<template>
    <b-card :title="`${$t('edit')}`">
        <form @submit.prevent="updatePayment" @keydown="form.onKeydown($event)" class="edit-form" enctype="multipart/form-data">
            <b-row>
                <b-col sm="6">
                    <div class="form-group">
                        <label for="name">{{ $t('name') }}<span class="text-danger">*</span></label>
                        <input type="text" name="name" class="form-control" v-model="form.name" :class="{ 'is-invalid': form.errors.has('name') }">
                        <has-error :form="form" field="name" />
                    </div>
                    <div class="form-group">
                        <label for="description">{{ $t('description') }}<span class="text-danger">*</span></label>
                        <textarea class="form-control" v-model="form.description" :class="{ 'is-invalid': form.errors.has('description') }"></textarea>
                        <has-error :form="form" field="description" />
                    </div>
                    <div class="form-group">
                        <label for="status">{{ $t('status') }}<span class="text-danger">*</span></label>
                        <select v-model="form.status" class="form-control">
                            <option value="1">{{ $t('enabled') }}</option>
                            <option value="0">{{ $t('disabled') }}</option>
                        </select>
                        <has-error :form="form" field="status" />
                    </div>
                    <div class="form-group">
                        <label for="type">{{ $t('default_selected') }}<span class="text-danger">*</span></label>
                        <select v-model="form.selected" class="form-control">
                            <option :value="true">{{ $t('selected') }}</option>
                            <option :value="false">{{ $t('deselected') }}</option>
                        </select>
                        <has-error :form="form" field="type" />
                    </div>
                </b-col>
                <b-col sm="6">
                    <h5>{{ $t('show_for') }}:</h5>
                    <div v-for="item in deliveries" class="custom-control custom-checkbox" :key="item.id">
                        <input type="checkbox" class="custom-control-input" :id="`checkbox-${item.id}`" v-model="form.show_for" :value="item.id">
                        <label class="custom-control-label" :for="`checkbox-${item.id}`">{{ item.name }}</label>
                    </div>
                    <has-error :form="form" field="show_for" />
                </b-col>
            </b-row>
            <button type="submit" class="btn btn-success" :disabled="form.busy">{{ $t('edit') }}</button>
        </form>
    </b-card>
</template>
<script>
import Form from 'vform'
import axios from 'axios'

export default {
    middleware: 'auth',
    layout: 'default',
    metaInfo () {
        return { title: this.$t('edit') }
    },
    data: () => ({
        form: new Form({
            name: '',
            description: '',
            status: '',
            selected: false,
            show_for: []
        }),
        deliveries: null
    }),
    mounted () {
        this.fetchData()
        this.fetchDeliveries()
    },
    methods: {
        async updatePayment () {
            this.form.busy = true
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            const id = this.$route.params.id
            await this.form.put(apiUrl + 'payments/' + id).then(resp => {
                this.$router.push({ name: 'payments' })
            }).catch(error => {
                this.form.errors.set(error.response.data.error.errors)
            })
        },
        async fetchData () {
            const id = this.$route.params.id
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            const { data } = await axios.get(apiUrl + 'payments/' + id)
            this.form.fill(data.data)
            this.delivery = data.data
        },
        async fetchDeliveries () {
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            const { data } = await axios.get(apiUrl + 'deliveries/list')
            this.deliveries = data.data
        }
    }
}
</script>
